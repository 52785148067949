/*==========================
    Service Area 
===========================*/

.service {
  // Service Style One
  &.service__style--1 {
    padding: 40px 0;
    @extend %transition;
    @extend %posrelative;
    z-index: 2;
    @media #{$sm-layout} {
      padding: 15px 0;
    }
    @media #{$md-layout} {
      padding: 20px 0;
    }

    .icon {
      img {
        margin-bottom: 34px;
        @media #{$sm-layout} {
          margin-bottom: 14px;
          height: 53px;
        }
        @media #{$large-mobile} {
          margin-bottom: 13px;
          height: 45px;
        }
      }
    }

    .content {
      h4 {
        &.title {
          margin-bottom: 20px;
          font-weight: 400;
          font-size: 24px;
          @media #{$sm-layout} {
            margin-bottom: 12px;
            font-weight: 400;
            font-size: 20px;
          }
          @media #{$large-mobile} {
            margin-bottom: 9px;
            font-size: 18px;
          }

        }
      }

      p {
        opacity: 0.75;
        font-weight: 300;
      }
    }
  }

  &.service__style--2 {
    padding: 30px 35px;
    @extend %transition;
    @extend %posrelative;
    z-index: 2;
    border-radius: 10px;
    @media #{$laptop-device} {
      padding: 30px 28px;
    }
    @media #{$md-layout} {
      padding: 30px 25px;
    }
    @media #{$sm-layout} {
      padding: 30px 14px;
    }
    @media #{$large-mobile} {
      padding: 30px 18px;
      margin-top: 30px;
    }

    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      background-image: linear-gradient(to right, #f7cb05, #f7cb05);
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      @extend %transition;
      border-radius: 10px;
      @media #{$large-mobile} {
        opacity: 1;
        visibility: visible;
      }
    }

    .icon {
      font-size: 54px;
      font-weight: 400;
      margin-bottom: 23px;
      display: inline-flex;
      color: $theme-color;
      @extend %transition;
      @media #{$md-layout} {
        margin-bottom: 11px;
      }
      @media #{$large-mobile} {
        //color: #ffffff;
        color: black !important;
      }

      svg {
        stroke-width: 1 !important;
      }
    }

    .content {
      h3 {
        &.title {
          margin-bottom: 19px;
          font-weight: 500;
          @extend %transition;
          @media #{$laptop-device} {
            font-size: 19px;
          }
          @media #{$sm-layout} {
            font-size: 20px;
            color: black !important;
          }
          @media #{$md-layout} {
            margin-bottom: 8px;
          }
          @media #{$large-mobile} {
            color: #ffffff;
          }

          a {
            color: inherit;
            @media #{$large-mobile} {
              color: #ffffff;
            }
          }
        }
      }

      p {
        @extend %transition;
        color: rgba(29, 29, 36, 0.75);
        @media #{$large-mobile} {
            color: black !important;
        }

      }
    }

    &:hover {
      box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
      transform: translateY(-5px);

      &::before {
        opacity: 1;
        visibility: visible;
      }

      .icon {
        color: black !important;
      }

      .content {
        h3 {
          &.title {
            color: black !important;
            @extend %textBlack;
          }
        }

        p {
          color: black !important;
          @extend %textBlack;
        }
      }
    }

    &.service-left-align {
      display: flex;
      padding: 50px 24px;

      .icon {
        padding-right: 20px;
      }
    }
  }

}


.text-center,
.text-left {
  .service {
    &.service__style--2 {
      padding: 60px 45px;

      @media #{$sm-layout} {
        padding: 60px 14px;
      }

      @media #{$lg-layout} {
        padding: 50px 11px;
      }

      @media #{$md-layout} {
        padding: 60px 25px;
      }
    }
  }
}

// Sercvice Style One
.service-white {
  // Service Style One
  .service__style--1 {
    z-index: 2;

    .content {
      h4 {
        &.title {
          color: #c6c9d8;
        }
      }

      p {
        color: #c6c9d8;

      }
    }
  }

  .t12-service {
    .icon {
      font-size: 54px;
      font-weight: 400;
      margin-bottom: 23px;
      display: inline-flex;
      color: $theme-color;
      @extend %transition;
      @media #{$md-layout} {
        margin-bottom: 11px;
      }
      @media #{$large-mobile} {
        color: #ffffff;
      }

      svg {
        stroke-width: 1 !important;
      }
    }
  }
}

/*---------------------------
    Paralax Service Style  
-----------------------------*/

.rn-paralax-service {
  .service__style--2 {
    z-index: 2;
    padding: 40px 40px;
    background: rgba(255, 255, 255, 0.3);
    margin-top: 40px;
    overflow: hidden;
    border: 2px solid transparent;

    @media #{$sm-layout} {
      padding: 30px 20px;
      background: transparent;
      margin-top: 30px;
    }

    .icon {
      color: #ffffff;
    }

    .content {
      h3 {
        &.title {
          font-size: 19px;
          color: #ffffff;
        }
      }

      p {
        color: rgba(255, 255, 255, 0.75);
      }
    }

    &:hover {
      border: 2px solid $theme-color;

      &::before {
        border-radius: 0;
      }
    }
  }
}

/* Creative Agency Service  */
.creative-service-wrapper {
  .row {
    &.creative-service {
      a {
        display: flex;
        height: 100%;
      }

      .service {

        &.service__style--2 {
          margin-top: 30px;
          background: #f6f6f6;
        }
      }
    }
  }

}


.service-one-wrapper {
  @media #{$md-layout} {
    margin: 0 -40px;
  }
}


.web-development-cover-img {
  background-image: url(/assets/images/service/us-hotels/header-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.web-development-cover-img-service {
  background-image: url(/assets/images/service/web-development.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.mobile-development-cover-img-service {
  background-image: url(/assets/images/service/mobile-development.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.coach-ranking-cover-img {
  background-image: url(/assets/images/service/coach-ranking/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.ant-design-cover-img {
  background-image: url(/assets/images/service/ant-design/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.off-shore-cover-img {
  background-image: url(/assets/images/service/off-shore-team.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

}

.off-shore-img-1 {
  background-image: url(/assets/images/service/off-shore.png);
  background-repeat: no-repeat;
  height: 550px;
  background-size: cover;
  background-position: center center;

}

.conversational-cover-img {
  background-image: url(/assets/images/service/conversational-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

}

.cloud-dev-ops-cover-img {
  background-image: url(/assets/images/service/dev-ops-1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

}
.aws-cloud-sol-cover-img {
  background-image: url(/assets/images/service/bridge-at-night-time.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

}

.web-development-img-1 {
  background-image: url(/assets/images/service/person.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 550px;

}

.web-development-img-2 {
  background-image: url(/assets/images/service/web-design.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;

}

.dev-ops-img-1 {
  background-image: url(/assets/images/service/dev-ops-1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;

}

.cloud-migrations-img {
  background-image: url(/assets/images/service/orange-sky-2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;

}
.infra-automation-img {
  background-image: url(/assets/images/service/abstract-orange-2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;
}
.managed-cloud-services-img {
  background-image: url(/assets/images/service/abstract-orange-3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;
}
.managed-devops-img {
  background-image: url(/assets/images/service/abstract-orange-4.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;
}

.speed3-img {
  background-image: url(/assets/images/service/speed-3-w900.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;
}
.speed4-img {
  background-image: url(/assets/images/service/speed-4-w900.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;
}
.speed5-img {
  background-image: url(/assets/images/service/speed-5-w900.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;
}



.conversational-img-1 {
  background-image: url(/assets/images/service/laptop.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 550px;

}

.mt-50 {
  margin-top: 13%;
}

.border-radius-50 {
  border-radius: 27px !important;
}

.line-separator-p {
  border-bottom: 2px solid $theme-color;
}

.font-22 {
  font-size: 22px;
}

.h-80 {
  height: 80%;
}


