.rn-blog-details .inner ul, 
.rn-blog-details .inner ol {
  font-size: 18px;
}

@media (max-width: 767px) {
  .hidden-mobile {
    display: none;
  }
}

/* .mb--40 {
  margin-bottom: 40px;
} */