.active-dark {
  ul.technologies img {
    filter: none !important;
  }
}
.inline-grid{
  display: inline-grid;
}
.w-80{
  width: 80%;
}
.w-60{
  width: 60%;
}
.h-110{
  height: 110px !important;
  @media #{$sm-layout} {
    height: 80px !important;
  }
  img{
    height: 100px;
    @media #{$sm-layout} {
      height: 80px;
    }
  }
}
.h-200{
  height: 200px;
}
.m-h-300{
  max-height: 300px;
}

.pt-10{
  padding-top: 10%;
}

.pt-5{
  padding-top: 5%;
}
.pt-15{
  padding-top: 15px;
}
